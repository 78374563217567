.Toastify {
  border-radius: 10px;
}

.Toastify__toast-body {
  text-align: justify;
  font-size: 15px;
  font-family: Roboto;
  line-height: 17.5px;
  font-weight: 400;
}

.Toastify__toast--warning {
  background: #FEDF84 !important;
  color: #001B43;
  border-radius: 5px;
}

.Toastify__toast--warning .Toastify__progress-bar {
  background-color: #001B43;
}

.Toastify__toast--error {
  background: #FF6767 !important;
  color: #001B43;
  border-radius: 5px;
}

.Toastify__toast--error .Toastify__progress-bar {
  background-color: #001B43;
}

.Toastify__toast--info {
  background: #001B43;
  color: #fff;
  border: 0.1px solid white;
  border-radius: 5px;
}

.Toastify__toast--info .Toastify__progress-bar {
  /* background-color: #2FF6D3; */
    transform-origin: right;
  background-size: 30px 30px;
  background-image: linear-gradient(
      135deg,
      #2FF6D3 25%,
      #2FF6D3  25%,
      #001B43 50%,
      #2FF6D3 50%,
      #2FF6D3 75%,
      #2FF6D3 75%,
      #001B43
  );
}

.Toastify__toast--success {
  background: #2FF6D3 !important;
  color: #001B43;
  border-radius: 5px;
  transition: 5s;
}

.Toastify__toast--success .Toastify__progress-bar {
  background-color: #001B43;
}

.Toastify__close-button svg {
  width: 25px;
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress 1 forwards;
  transition: 3s;
}

.Toastify__progress-bar {
  transform-origin: right;
  background-size: 30px 30px;
  background-image: linear-gradient(
      135deg,
      rgba(255, 255, 255, .15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, .15) 50%,
      rgba(255, 255, 255, .15) 75%,
      transparent 75%,
      transparent
  );
}

.cookieBTN {
  background: #09032e;
  border: 1px solid white;
  border-radius: 30px;
  color: #fff;
  padding: 10px 30px 10px 30px;
  margin-right: 30px;
  font-size: 15px;
}

.cookieBTN:hover {
  color: #09032e;
  background: #fff;
  cursor: pointer;
  } 


  input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #1a2b44 inset !important;
    -webkit-text-fill-color: #fff !important;

  
}


