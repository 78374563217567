html,
body,
#root {
  height: 100%;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;



  --modal-body-color: rgb(0, 19, 47);
  --overlay-color: rgba(0, 27, 67, 0.9);
  --wallet-button-color: rgb(25, 42, 67);
  --wallet-button__hover-color: rgb(38, 54, 78);

}


.web3-wc_modal_container{

  width: 530px;
    
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .intercom-lightweight-app-launcher {
  bottom: 50px !important;
} */


/**
  Helper classes
*/
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flex-justify-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.flex-justify-space {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.flex-justify-end {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.flex-justify-start {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.flex-space-around {
  -webkit-box-pack: space-around;
  -webkit-justify-content: space-around;
      -ms-flex-pack: space-around;
          justify-content: space-around;
}
.flex-align-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}
.flex-wrap {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.flex-one {
  flex: 1
}
.width-100 {
  width: 100%;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.no-margin {
  margin: 0;
}
.no-padding {
  padding: 0;
}

